import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0c202b90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-availability-day-container" }
const _hoisted_2 = { class: "v-edit-button" }
const _hoisted_3 = { class: "v-availability-day-content" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_AvailabilityEditModal = _resolveComponent("AvailabilityEditModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["v-corner", _ctx.cornerClass])
      }, null, 2),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseIcon, {
          class: "v-edit-day",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditModal())),
          name: "pencil",
          size: "sm"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.day.available)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.day.timeIntervals, (interval, i) => {
                return (_openBlock(), _createElementBlock("div", { key: i }, _toDisplayString(interval.start) + " - " + _toDisplayString(interval.finish), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.day.note), 1)
      ])
    ]),
    (_ctx.showEdit)
      ? (_openBlock(), _createBlock(_component_AvailabilityEditModal, {
          key: 0,
          day: _ctx.day,
          onSaveDay: _ctx.saveDay,
          onCloseAvailabilityModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeEditModal()))
        }, null, 8, ["day", "onSaveDay"]))
      : _createCommentVNode("", true)
  ], 64))
}