import { StyleValue } from 'vue';

interface IInputProps extends Object {
  border?: boolean;
  required?: boolean;
  labelWidth?: string;
  boldLabel?: boolean;
  inputWidth?: string;
  inputHeight?: string;
  transparent?: boolean;
  inverted?: boolean;
  isDisabled?: boolean;
  icon?: string;
}

export const useInputMethods = (props: IInputProps) => {
  function inputClass() {
    let classes = '';
    classes += props.border ? 'v-border' : 'v-no-border';
    classes += props.icon ? ' v-input-icon' : '';
    return classes;
  }

  function inputStyle() {
    const style: StyleValue = {};
    if (props.inputWidth) { style.width = props.inputWidth; }
    if (props.inputHeight) { style.height = props.inputHeight; }
    if (props.transparent) { style.background = 'transparent'; }
    return style;
  }

  function labelClass() {
    let classes = 'v-label';
    if (props.required) { classes = `${classes} v-required`; }
    return classes;
  }

  function labelStyle() {
    const style: StyleValue = {};
    if (props.labelWidth) {
      style.minWidth = props.labelWidth;
      style.width = props.labelWidth;
    } else if (props.inputWidth) {
      style.minWidth = props.inputWidth;
      style.width = props.inputWidth;
    }
    if (props.boldLabel) {
      style.color = 'black';
      style.fontWeight = 'bold';
    }
    return style;
  }

  return {
    inputClass, inputStyle, labelClass, labelStyle,
  };
};

interface IPositionProps extends Object {
  position?: string;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
}

export const usePositionMethods = (props: IPositionProps) => {
  const computePosition = () => {
    const { position } = props;
    const { top } = props;
    const { left } = props;
    const { bottom } = props;
    const { right } = props;
    return `position: ${position}; top: ${top}; right: ${right}; bottom: ${bottom}; left: ${left}`;
  };
  return {
    computePosition,
  };
};
