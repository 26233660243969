import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6bc3a546"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-patient-details-table" }
const _hoisted_2 = { class: "v-resident-name" }
const _hoisted_3 = { class: "v-data-header" }
const _hoisted_4 = { class: "v-data" }
const _hoisted_5 = { class: "v-data-header" }
const _hoisted_6 = { class: "v-data" }
const _hoisted_7 = { class: "v-data-header" }
const _hoisted_8 = { class: "v-data" }
const _hoisted_9 = { class: "v-data-header" }
const _hoisted_10 = { class: "v-data" }
const _hoisted_11 = { class: "v-data-header" }
const _hoisted_12 = { class: "v-data" }
const _hoisted_13 = { class: "v-data-header" }
const _hoisted_14 = { class: "v-data" }
const _hoisted_15 = { class: "v-data-header" }
const _hoisted_16 = { class: "v-data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, { class: "v-patient-details-card" }, {
    content: _withCtx(() => [
      _createElementVNode("table", _hoisted_1, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.response.personalDetails?.name), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.translate("gp_connect.address")), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.response.personalDetails?.address), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.translate("gp_connect.phone")), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.response.personalDetails?.phone), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.translate("gp_connect.gp_details")), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.response.personalDetails?.gpName), 1)
          ])
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.translate("gp_connect.dob")), 1),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.getClientDate(_ctx.response)), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.translate("person.gender")), 1),
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.response.personalDetails?.gender), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.translate("gp_connect.nhs_number")), 1),
            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.response.personalDetails?.nhsNumber), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.translate("gp_connect.pas_number")), 1),
            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.response.personalDetails?.pasNumber), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}