import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-23b04a9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-list-entry" }
const _hoisted_2 = { class: "v-list-entry-inner v-no-padding v-highlight-on-hover" }
const _hoisted_3 = {
  key: 0,
  class: "v-left-column-wrapper v-checkbox-wrapper"
}
const _hoisted_4 = {
  key: 0,
  class: "v-list-avatar"
}
const _hoisted_5 = { class: "v-main-col" }
const _hoisted_6 = { class: "v-primary-text v-dataset-entry" }
const _hoisted_7 = { class: "v-secondary-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!
  const _component_BaseAvatar = _resolveComponent("BaseAvatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-entry-wrapper", _ctx.checkState]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleChecked && _ctx.toggleChecked(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.showCheckbox)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_BaseCheckbox, {
                radio: _ctx.isSingle,
                checked: _ctx.isSelected,
                class: "v-hide"
              }, null, 8, ["radio", "checked"]),
              (_ctx.showAvatar)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_BaseAvatar, {
                      photo: _ctx.entry.photo,
                      size: "small"
                    }, null, 8, ["photo"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.primaryText), 1),
          _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.secondaryText), 1)
        ])
      ])
    ])
  ], 2))
}