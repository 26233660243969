import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ad626562"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "v-help-banner--info-faded v-help-banner u-border-bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InteractionSection = _resolveComponent("InteractionSection")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedParameters, (parameter, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: parameter.id
      }, [
        _withDirectives(_createVNode(_component_InteractionSection, null, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getParameterType(parameter)), {
              parameter: parameter,
              readOnly: _ctx.readOnly || parameter.readOnly || _ctx.isDataPointToSaveForLater(parameter),
              index: index,
              clientId: (parameter.valueType == 'picture') ? _ctx.currentInteraction.clientId : '',
              iconUrl: (parameter.valueType == 'picture') ? _ctx.currentInteraction.iconUrl : ''
            }, null, 8, ["parameter", "readOnly", "index", "clientId", "iconUrl"]))
          ]),
          _: 2
        }, 1536), [
          [_vShow, _ctx.shouldShowParameter(parameter)]
        ])
      ]))
    }), 128)),
    (_ctx.showComputedInfoText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.translate('timeline.interaction.calculated_data')), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleComputedParameters, (computedParameter) => {
      return (_openBlock(), _createElementBlock("div", {
        key: computedParameter.id
      }, [
        _createVNode(_component_InteractionSection, null, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getParameterType(computedParameter)), {
              parameter: computedParameter,
              readOnly: true
            }, null, 8, ["parameter"]))
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ], 64))
}