import { RouteAuthContext } from '@/_shared/router/types';
import ProfessionalInfoRightPanelMain from '@/professional_info/ProfessionalInfoRightPanelMain.vue';
import ProfessionalInfoSideBar from '@/professional_info/ProfessionalInfoSideBar.vue';
import BasePlaceHolderView from '@/_shared/components/routing/BasePlaceHolderView.vue';
import InteractionsRightPanelMain from '@/timeline/InteractionsRightPanelMain.vue';
import InteractionsLeftPanel from '@/timeline/components/InteractionsLeftPanel.vue';
import { RouteLocationNormalized } from 'vue-router';

export const carerRoutes = [
  {
    path: '/carers',
    components: {
      TopBar: BasePlaceHolderView,
    },
    meta: { needSyncAngular: true },
    children: [
      {
        path: '',
        components: {
          Action: BasePlaceHolderView,
          SideBar: BasePlaceHolderView,
        },
        children: [
          {
            path: '',
            name: 'carerList',
            component: BasePlaceHolderView,
            meta: { isAngular: true },
          },
          {
            path: 'new',
            name: 'carerList.new',
            component: BasePlaceHolderView,
            meta: { isAngular: true },
          },
        ],
      },
    ],
  },
  {
    path: '/carers/:carerId(\\d+)',
    components: {
      TopBar: BasePlaceHolderView,
    },
    children: [
      {
        path: 'certification',
        components: {
          Action: BasePlaceHolderView,
          SideBar: ProfessionalInfoSideBar,
        },
        children: [
          {
            path: ':section*',
            name: 'carer.certification',
            component: ProfessionalInfoRightPanelMain,
            props: (route: RouteLocationNormalized) => ({ section: route.params.section, carerId: +route.params.carerId }),
          },
        ],
        props: true,
      },
      {
        path: '/carers/:carerId(\\d+)/',
        components: {
          Action: BasePlaceHolderView,
          SideBar: InteractionsLeftPanel,
        },
        children: [
          {
            path: '',
            name: 'carer.timeline',
            component: BasePlaceHolderView,
            props: false,
            meta: { isAngular: true },
          },
          {
            path: 'interactions/:id(\\d+)',
            name: 'carer.timeline.interaction',
            component: InteractionsRightPanelMain,
            props: (route: RouteLocationNormalized) => ({ id: route.params.id }),
          },
          { // HACK this is needed until we implement care plan review in Vue
            path: 'needinteractions/:id(\\d+)',
            name: 'carer.timeline.needinteraction',
            component: BasePlaceHolderView,
            props: false,
            meta: { isAngular: true },
          },
        ],
      },
      {
        path: 'profile',
        meta: { isAngular: true },
        components: {
          Action: BasePlaceHolderView,
          SideBar: BasePlaceHolderView,
        },
        children: [
          {
            path: '',
            name: 'carer.profile',
            component: BasePlaceHolderView,
          },
          {
            path: 'sections/general_info',
            name: 'carer.profile.generalInfo',
            component: BasePlaceHolderView,
          },
          {
            path: 'sections/access_information',
            name: 'carer.profile.accessInformation',
            component: BasePlaceHolderView,
          },
          {
            path: 'sections/address',
            name: 'carer.profile.address',
            component: BasePlaceHolderView,
          },
          {
            path: 'sections/biography',
            name: 'carer.profile.biography',
            component: BasePlaceHolderView,
          },
          {
            path: 'sections/organisation_state',
            name: 'carer.profile.organisationState',
            component: BasePlaceHolderView,
          },
          {
            path: 'sections/additional_info',
            name: 'carer.profile.additionalInfo',
            component: BasePlaceHolderView,
          },
          {
            path: 'documents',
            name: 'carer.profile.documents',
            component: BasePlaceHolderView,
          },
        ],
      },
    ],
    props: true,
  },
];

export const allowedCarerRoutes = (authContext: RouteAuthContext) => {
  const allowedRoutes: string[] = [];
  if (authContext.userHasPermission('viewCarers')) {
    allowedRoutes.push(
      'carerList',
      'carer',
      'carer.timeline',
      'carer.timeline.interaction',
      // HACK this is needed until we implement care plan review in Vue
      'carer.timeline.needinteraction',
      'carer.profile',
      'carer.profile.generalInfo',
      'carer.profile.accessInformation',
      'carer.profile.address',
      'carer.profile.biography',
      'carer.profile.organisationState',
      'carer.profile.additionalInfo',
      'carer.profile.documents',
      'carer.profile.printAbstract',
      'carer.profile.print',
      'carer.certification',
      'carer.certification.section',
    );
  }

  if (authContext.userHasPermission('manageCarers')) {
    allowedRoutes.push('carerList.new');
  }

  return allowedRoutes;
};
