import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8b8ba3c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-client-types--main-col__edit" }
const _hoisted_2 = { class: "v-client-types--update-input-text" }
const _hoisted_3 = { class: "v-client-types--update-v-input-box" }
const _hoisted_4 = { class: "v-warning-text" }
const _hoisted_5 = { class: "v-client-types--right-col v-client-types--buttons-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BaseIcon, {
        name: "icon-danger nr-icon-cancel",
        popover: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancelEditing'))),
        size: "sm",
        "no-hover-background": "",
        class: "v-client-types--cancel-icon"
      }),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.translate('client_types.edit_name_label')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseInput, {
          border: "",
          modelValue: _ctx.clientTypeName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.clientTypeName) = $event)),
          onInput: _ctx.validateName
        }, null, 8, ["modelValue", "onInput"]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.warningMessage), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_BaseButton, {
        class: "v-save-client-type",
        onClicked: _ctx.saveClientType,
        size: "large",
        disabled: _ctx.saveDisabled
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate('common.save')), 1)
        ]),
        _: 1
      }, 8, ["onClicked", "disabled"])
    ])
  ], 64))
}