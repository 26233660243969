import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-d080fd38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "availability-time-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseToggle = _resolveComponent("BaseToggle")!
  const _component_BaseTimePicker = _resolveComponent("BaseTimePicker")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    title: "Availability",
    icon: "round-icon round-icon-sm temp-icon-size-reduce nr-icon-schedule",
    confirmText: "OK",
    onCloseModal: _ctx.closeModal,
    onConfirm: _ctx.saveDay,
    confirmDisabled: !_ctx.valid
  }, {
    content: _withCtx(() => [
      _createVNode(_component_BaseToggle, {
        labelWidth: "100px",
        boldLabel: "",
        label: `${_ctx.translate('professional_info.availability.available_notice')}:`,
        checked: _ctx.available,
        onToggleChange: _ctx.toggleAvailable,
        class: "v-availability-available-toggle"
      }, null, 8, ["label", "checked", "onToggleChange"]),
      (_ctx.available)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeIntervalsWithHash, (interval, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "v-availability-time-interval",
                key: interval.hash
              }, [
                _createVNode(_component_BaseTimePicker, {
                  class: "v-availability-time-start",
                  labelWidth: "100px",
                  boldLabel: "",
                  border: "",
                  required: "",
                  label: `${_ctx.translate('professional_info.availability.from')}:`,
                  modelValue: _ctx.timeIntervals[i].start,
                  "onUpdate:modelValue": ($event: any) => ((_ctx.timeIntervals[i].start) = $event)
                }, null, 8, ["label", "modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_BaseTimePicker, {
                  class: "v-availability-time-finish",
                  labelWidth: "100px",
                  boldLabel: "",
                  border: "",
                  required: "",
                  label: `${_ctx.translate('professional_info.availability.to')}:`,
                  modelValue: _ctx.timeIntervals[i].finish,
                  "onUpdate:modelValue": ($event: any) => ((_ctx.timeIntervals[i].finish) = $event)
                }, null, 8, ["label", "modelValue", "onUpdate:modelValue"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_BaseIcon, {
                    disabled: _ctx.timeIntervals.length === 1,
                    name: "minus",
                    size: "lg",
                    onClick: ($event: any) => (_ctx.removeTimeInterval(i))
                  }, null, 8, ["disabled", "onClick"]),
                  (_ctx.timeIntervals.length === i+1)
                    ? (_openBlock(), _createBlock(_component_BaseIcon, {
                        key: 0,
                        name: "plus",
                        size: "lg",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addNewTimeInterval()))
                      }))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_BaseToggle, {
        labelWidth: "100px",
        boldLabel: "",
        label: `${_ctx.translate('professional_info.availability.flexible_notice')}:`,
        checked: _ctx.flexible,
        onToggleChange: _ctx.toggleFlexible,
        class: "v-availability-flexible-toggle"
      }, null, 8, ["label", "checked", "onToggleChange"]),
      _createVNode(_component_BaseInput, {
        labelWidth: "100px",
        boldLabel: "",
        border: "",
        textarea: "",
        label: "Note",
        modelValue: _ctx.note,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.note) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["onCloseModal", "onConfirm", "confirmDisabled"]))
}